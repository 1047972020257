<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-22 09:11:33
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2025-03-12 16:29:22
 * @FilePath: \qcwp-vue\packages\web\src\components\page\home\car\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!-- 车辆分类和推荐 -->
<script setup lang='ts'>
import { useCarComponents } from '~~/src/pages/index/hooks/car'

const { isDesktop } = useDevice()
const {
  active: carActive,
  activeType: carActiveType,
  classes: carClasses,
  initHandle: carInitHandler,
} = useCarComponents()

try {
  await carInitHandler()
}
catch (error) {
  recordError(error)
}
</script>

<template>
  <div v-if="isDesktop" class="hidden md:block">
    <page-home-car-pc v-model:active="carActive" v-model:type="carActiveType" :classes="carClasses" />
  </div>
  <div v-else class="px-3 mt-3 md:hidden">
    <page-home-car-mobile :classes="carClasses" />
  </div>
</template>
