import type { OmitPageParam } from '@qcwp/common'
import { PaginationStatus, newsHomePath, newsTypePath } from '@qcwp/common'
import { isClient, isNumber } from '@qcwp/utils'
import { getArticleColumnList, getArticleList, getArticleRecommendList } from '~~/src/server/modules/article'
import type { ArticleListParams, ArticlePagesResponse, ArticleRecommendParams } from '~~/src/server/modules/article/type'
import { ArticleRecomLocationEnum } from '~~/src/server/modules/article/type'
import { ARTICLE_LIST_TYPE } from '~~/src/store'
import type { PaginationNuxtFn } from '~~/src/composables/usePaginationNuxt'

export interface NewClassBase {
  type: ARTICLE_LIST_TYPE
  label: string
  moreUrl: string
  pagination: PaginationNuxtFn
  toMorePage?: boolean
}
export interface NewArticleListClass extends NewClassBase {
  key: 'articleList'
  params: OmitPageParam<ArticleListParams>
  list: ArticlePagesResponse[]
}
export interface NewArticleListRecommendClass extends NewClassBase {
  key: 'articleRecommend'
  params: OmitPageParam<ArticleListParams>
  list: ArticlePagesResponse[]
}
export interface NewArticleListColumnClass extends NewClassBase {
  key: 'articleColumn'
  params: OmitPageParam<ArticleRecommendParams>
  list: ArticlePagesResponse[]
}
export type NewClass = NewArticleListClass | NewArticleListRecommendClass | NewArticleListColumnClass
export function moduleNewComponent(maxCount = 50) {
  const { isMobileOrTablet } = useDevice()
  const currentCol = ref(0)
  const classes = reactive<NewClass[]>([
    {
      type: ARTICLE_LIST_TYPE.RECOMMEND,
      key: 'articleRecommend',
      label: '最新推荐',
      moreUrl: newsHomePath(),
      params: { articleRecomLocation: ArticleRecomLocationEnum.HOME },
      pagination: usePaginationNuxt({ pageSize: 12 }),
      list: [],
    },
    {
      type: ARTICLE_LIST_TYPE.COLUMN,
      key: 'articleColumn',
      label: '试驾测评',
      moreUrl: newsTypePath('8'),
      params: {
        columnId: '8',
      },
      pagination: usePaginationNuxt({ pageSize: 12 }),
      list: [],
    },
    {
      type: ARTICLE_LIST_TYPE.NORMAL,
      key: 'articleList',
      label: '网评资讯',
      moreUrl: newsHomePath(),
      params: {
        mediaUserId: '62338',
      },
      pagination: usePaginationNuxt({ pageSize: 12 }),
      list: [],
    },
  ])

  const router = useRouter()
  function goMorePage() {
    const currentColItem = classes[currentCol.value]
    router.push(currentColItem.moreUrl)
  }
  function getCurrentCol(index?: number) {
    if (!isNumber(index))
      index = currentCol.value
    return classes[index]
  }
  /**
   * 更新分页数据和列表数据
   */
  function updatePageData<T extends ArticlePagesResponse>(data: T[], index?: number) {
    const currentColItem = getCurrentCol(index)
    currentColItem.list.push(...(data || []))
    // 是否变成跳转到列表页
    // 移动端时不限制加载条数
    if (!isMobileOrTablet && currentColItem.list?.length >= maxCount)
      currentColItem.toMorePage = true
  }

  function getData(index?: number) {
    const currentColItem = getCurrentCol(index)
    const fn = currentColItem.pagination.loadPaginationAsyncData
    switch (currentColItem.key) {
      case 'articleRecommend':
        return fn('home-article-0', getArticleRecommendList, currentColItem.params)
      case 'articleColumn':
        return fn('home-article-1', getArticleColumnList, currentColItem.params)
      default:
        return fn('home-article-2', getArticleList, currentColItem.params)
    }
  }
  async function getListHandler(index?: number, isRefresh = false) {
    const currentColItem = getCurrentCol(index)
    isRefresh && currentColItem.pagination.setPage(1)
    isRefresh && currentColItem.pagination.setPaginationStatus(PaginationStatus.INIT)
    // 是否变成跳转到列表页
    if (currentColItem?.toMorePage)
      return
    try {
      const fn = currentColItem.pagination.loadPaginationData
      let promise
      if (currentColItem.key === 'articleRecommend')
        promise = fn(getArticleRecommendList, currentColItem.params)
      else if (currentColItem.key === 'articleColumn')
        promise = fn(getArticleColumnList, currentColItem.params)
      else
        promise = fn(getArticleList, currentColItem.params)
      const { list = [] } = (await promise) || {}

      !isRefresh && updatePageData(list, index)
      isRefresh && (currentColItem.list = list)
    }
    catch (error) {
      recordError(error)
    }
  }
  async function setupHandler(index?: number) {
    const asyncData = await getData(index)
    if (!asyncData)
      return
    const { data } = useNuxtAsyncHandle(asyncData)
    data && updatePageData(data.list, index)
  }

  async function refreshData() {
    getListHandler(currentCol.value, true)
  }

  async function serverInitHandler() {
    const promises = []

    for (const i in classes)
      promises.push(setupHandler(Number(i)))

    await Promise.all(promises)
  }

  if (isClient) {
    const stop = watch(currentCol, () => {
      if (!classes[currentCol.value].list.length && classes[currentCol.value].pagination.canLoadMore())
        getData()
    })
    onBeforeUnmount(() => stop())
  }

  return {
    classes,
    currentCol,
    goMorePage,
    getListHandler,
    serverInitHandler,
    refreshData,
  }
}
